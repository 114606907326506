import { Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import Layout from '../../components/layout'
import NewHeader from '../../components/NewHeader'
import SEO from '../../components/seo'
import ServiceImage from '../../images/services_c.webp'
import LanguageIcon from '@material-ui/icons/Language'
import MemoryIcon from '@material-ui/icons/Memory'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
const useStyles = makeStyles(theme => ({
  titleFont: {
    fontFamily: 'Volkhov',
    fontSize: 44,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  marginMainTop: {
    marginTop: -150,
    marginBottom: 84,
  },
  marginIPADTop: {
    marginTop: -200,
    marginBottom: 84,
  },
  mt24: {
    marginTop: 24,
  },
  white: {
    color: '#fff',
  },
  paddingAll24: {
    padding: 18,
  },
}))
const Structure = () => {
  const classes = useStyles()
  return (
    <div>
      <Layout>
        <SEO title="Services" />
        <NewHeader title="Services" image={ServiceImage} />
        <Container className={classes.marginMainTop}>
          <Typography className={clsx(classes.titleFont, classes.white)}>
            Services
        </Typography>
          <Grid item md={12} xs={12} sm={12}>
            <div className={classes.paddingAll24}>
              <Typography
                variant="body1"
                className={classes.mt24}
                align="justify"
              >
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Paper square elevation="4" style={{ padding: 44 }}>
                      <Grid container spacing={5} align="center" alignItems="center">
                        <Grid item md={6}>
                          <LanguageIcon fontSize="large" />
                          <Typography variant="h6">
                            Web app development
                          </Typography>
                          <Typography variant="body1" style={{ lineHeight: 2 }}>
                            Colloidle is one of the leading company in the web app development market in India. We also have the experience in the development of real time admin portals and systems. Our commitment is to deliver timely and great solutions to our customers.
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <PhoneAndroidIcon fontSize="large" />
                          <Typography variant="h6">
                            Mobile apps
                          </Typography>
                          <Typography variant="body1" style={{ lineHeight: 2 }}>
                            As per the survey, billions of the people are connected with eact other on the handheld mobile devices. So we are developing the solutions for both android and ios for our customer to match with the current market. We also provide hybrid solutions in app development.
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <MemoryIcon fontSize="large" />
                          <Typography variant="h6">
                            Embedded systems
                          </Typography>
                          <Typography variant="body1" style={{ lineHeight: 2 }}>
                            We have made a leap in the home automation industry with our partner company. We are providing a smart home technologies to the various sectors like flats, hotels and apartments. We have experience in developing IoT solutions in smart home and other embedded technologies.
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <AddAPhotoIcon fontSize="large" />
                          <Typography variant="h6">
                            Graphics design
                          </Typography>
                          <Typography variant="body1" style={{ lineHeight: 2 }}>
                            We also provide the graphics design for your products, which include icon design, banner design, video design. We have dedicated team to build the graphics things and meet the customer requirement and let the application looks wonderful. This also includes app wireframe and UI/UX design.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid></Typography></div></Grid></Container></Layout>
    </div>
  )
}

export default Structure
